const environment = {
	bontu: {
		domain: 'https://api.bontu-dev.io/v1',
		apiKey: '1XuWhNMCdX8rQIn5n5GIqd0NXgympxb9P2kVwVof'
	},
	production: true,
	hmr: false
};

export default environment;
